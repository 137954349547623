import React from "react";
import GeneralLayout from "../../../layouts/general";
import { useGlobalStore } from "../../../../store";
import page from "../..";
import SVG from "react-inlinesvg";

import errorStyle from "../index.module.styl";
import { ROUTES } from "../../../../common/routes.js";
import SEO from "../../../seo";

function InternalError() {
  const { i18n, breadcrumbs } = useGlobalStore();
  const pageTitle = `${i18n.t("meta_title_prefix")} - ${i18n.t(
    "meta_error_title",
  )}`;
  return (
    <GeneralLayout alignCenter breadcrumbs={breadcrumbs}>
      <SEO path={ROUTES.internalError()} title={pageTitle} />
      <div className={errorStyle.error}>
        <SVG src={require("../../../../images/icons/error.svg")} />
        <h1 className={errorStyle.errorCode}>
          {i18n.t("internal_error_code")}
        </h1>
        <h2 className={errorStyle.errorTitle}>
          {i18n.t("internal_error_title")}
        </h2>
        <div
          className={errorStyle.errorDescription}
          dangerouslySetInnerHTML={{
            __html: i18n.t("internal_error_description"),
          }}
        />
      </div>
    </GeneralLayout>
  );
}

export default page(InternalError);
